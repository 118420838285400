import {useParams} from 'react-router-dom';
import {useSurvey, useUpdateSurvey} from '../../hooks';

export const LastStageGreatDelivery = () => {
  const {surveyId} = useParams();
  const {data: survey} = useSurvey(surveyId);
  const {mutate} = useUpdateSurvey();

  const isPositiveSurvey = survey?.data?.SurveyAnswers.find(
    (el) => el.Name === 'OverallExperience' && el.Value === 5
  );

  const isDemoSurvey =
    survey?.data?.OrderData.ClientName === 'Demo' &&
    survey?.data?.OrderData?.RestaurantName === 'Demo';

  const openGMBReviewLink = () => {
    window.location.href = survey?.data?.GMBReviewLink;
  };

  return (
    <>
      <div className='survey__step lastStepGreat'>
        <div className='survey__title lg marginTop'>Thank you!</div>
        <div className='survey__text'>Your opinion matters.</div>
        {((survey?.data?.GMBReviewLink && isPositiveSurvey) ||
          isDemoSurvey) && (
          <>
            <div className='survey__text marginTop120'>Give us 5 stars</div>
            <div className='survey__action'>
              <span
                onClick={() => {
                  if (!survey?.data?.GMBReviewClicked && !isDemoSurvey) {
                    mutate({
                      id: surveyId,
                      GMBReviewClicked: true,
                      successCallback: () => {
                        openGMBReviewLink();
                      },
                      errorCallback: () => {
                        openGMBReviewLink();
                      }
                    });
                  } else {
                    openGMBReviewLink();
                    return false;
                  }
                }}
                className='btn-red buttonRateGoogle'
              >
                <span>Rate us on Google</span>
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};
